import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "collapse show" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_IreV2ValuesRecords = _resolveComponent("IreV2ValuesRecords")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Tabs, {
      tableUrl: "/manage/ire-v2/areas-listing",
      areaValueUrl: "/manage/ire-v2/areas-values",
      tableTitle: "Size DESC List",
      areaValueTitle: "Size DESC Values"
    }),
    (_ctx.can('read', 'instant-rent-estimator'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.translate('appartmentSize')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_IreV2ValuesRecords, {
              "table-data": _ctx.tableData,
              "table-header": _ctx.tableHeader,
              loading: _ctx.loading,
              "area-id": _ctx.selectIreArea,
              onListV2ValIre: _ctx.updateIreSizeValuesList,
              onSubmited: _ctx.onSubmitUpdate,
              "card-classes": "mb-6 mb-xl-9"
            }, null, 8, ["table-data", "table-header", "loading", "area-id", "onListV2ValIre", "onSubmited"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}